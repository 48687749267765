import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';


const Footer = () => {

  const data = useStaticQuery(graphql`
      query FooterQuery {
        site {
          siteMetadata {
            author {
              name
            }
            socials {
              name
              url
              username
            }
          }
        }
      }
    `)

  const name = data.site.siteMetadata.author.name
  const socials = data.site.siteMetadata.socials

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {socials &&
            socials.map((social, key) => {
              let url = social.url
              let username = social.username

              let social_url = url && username ? url + username : 'https://github.com/tgrandjean'
              return (
                <a
                  key={key}
                  href={social_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={social.name}
                >
                  <i className={`fa fa-${social.name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          {name} © {new Date().getFullYear()} - All rights reserved
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
