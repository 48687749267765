import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import App from '../components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default ({data}) => {
  const title = data.site.siteMetadata.title
  const lang = data.site.siteMetadata.lang
  const description = data.site.siteMetadata.description


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Gatsby Simplefolio'}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || 'Gatsby Simplefolio'} />
      </Helmet>
      <App />
    </>
  );
};

export const indexQuery = graphql`
  query indexQuery {
    site {
      siteMetadata {
        title
        lang
        description
      }
    }
  }
`
