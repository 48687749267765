import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Hero from './Hero/Hero';
import About from './About/About';
import Contact from './Contact/Contact';
import Projects from './Projects/Projects';
import Footer from './Footer/Footer';
import Skills from './Skills/Skills';



function App() {

  return (
    <>
      <Hero />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
      <CookieConsent
        buttonText="Ok, j'ai compris!"
        expires={150}
      >
        Ce site utilise des cookies pour améliorer l'expérience utilisateur et à des fins statistique.
      </CookieConsent>
    </>
  );
}

export default App;
