import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {

  const data = useStaticQuery(graphql`
    query MyQuery {
      site {
        siteMetadata {
          author {
            name
            title
            subtitle
            hero_cta
            hero_cta_contact
          }
          socials {
            name
            url
            username
          }
        }
      }
    }
    `)

  const author = data.site.siteMetadata.author
  const socials = data.site.siteMetadata.socials

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {author.title || 'Hi, my name is'}{' '}
            <span className="text-color-main">{author.name || 'Your Name'}</span>
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={800} distance="30px">
          <h2 className="hero-subtitle">
            {author.subtitle || "I'm the Unknown Developer."}
          </h2>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <Row>
            <Col>
              <p className="hero-cta">
                <span className="cta-btn cta-btn--hero">
                  <Link to="about" smooth duration={1000}>
                    {author.hero_cta || 'Know more'}
                  </Link>
                </span>
              </p>
            </Col>
            <Col>
              <p className="hero-cta">
                <span className="cta-btn cta-btn--hero">
                  <Link to="contact" smooth duration={1000}>
                    {author.hero_cta_contact || 'Contact me'}
                  </Link>
                </span>
              </p>
            </Col>
          </Row>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1500} distance="30px">
          <div className="hero-socials">
            {socials &&
              socials.map( (social, key) => {
                let url = social.url
                let username = social.username

                let social_url = url && username ? url + username : 'https://github.com/tgrandjean'

                return (
                    <a
                      key={key}
                      href={social_url}
                      rel={(social.name === 'phone') ? "nofollow" :"noopener noreferrer"}
                      target={(social.name === 'phone') ? "_self" :"_blank"}
                      aria-label={social.name}
                    >
                      <i className={`fa fa-${social.name || 'refresh'} fa-inverse`} />
                    </a>
                )
              })
            }
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
