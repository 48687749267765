import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import Title from '../Title/Title';
import SkillImg from '../Image/SkillImg';
import { useStaticQuery, graphql } from 'gatsby';

const Skills = () => {

  const data = useStaticQuery(graphql`
    query SkillsQuery {
          skillsJson {
            title
            skills {
                level
                logo
                name
                type
              }
            }
          }
    `)

    const title = data.skillsJson.title
    const skills = data.skillsJson.skills
    const types = [... new Set(skills.map( skill => (skill.type)))]


    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      if (window.innerWidth > 769) {
        setIsDesktop(true);
        setIsMobile(false);
      } else {
        setIsMobile(true);
        setIsDesktop(false);
      }
    }, []);

    return (
      <section id="skills">
        <Container>
          <div className="skills-wrapper">
          <Title title={title || 'Skills'} />
            {types.map( (type, key) => (
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={500}
                delay={(key + 1) * 100}
                distance="30px"
                key={key}>
                <Row className="text-center" style={{marginBottom: "1em"}}>
                  <Col>
                    <h2>{type}</h2>
                    <hr/>
                  </Col>
                </Row>
                <Row className="text-center align-items-center" style={{marginBottom: "5em"}}>
                  {skills.filter(skill => skill.type === type).map( (skill, key) => (
                    <OverlayTrigger
                      key={key}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Title>{skill.name}</Popover.Title>
                          <Popover.Content>
                            <span>{new Array(skill.level).fill(0).map((_, key) => (<i className="fa fa-star"/>))}</span>
                          </Popover.Content>
                        </Popover>
                      }>
                      <Col key={key} lg={2} md={4} sm={4} xs={6} style={{marginTop: "4em"}}>
                        <Tilt
                          options={{
                            scale: 1.5,
                            max: 35
                          }}
                        >
                          <div data-tilt>
                              <SkillImg alt={skill.name} filename={skill.logo} />
                          </div>
                        </Tilt>
                      </Col>
                    </OverlayTrigger>
                  ))}
                </Row>
              </Fade>
            ))}
          </div>
        </Container>
      </section>
    )
}

export default Skills
